/*eslint fp/no-let:0, fp/no-loops:0, fp/no-mutating-methods:0*/
/*eslint fp/no-mutating-assign:0, fp/no-mutation:0*/
const _ = require('lodash')
const MOBILE_COMPONENTS = 'mobileComponents'
const MASTER_PAGE_CHILDREN = 'children'
const COMPONENTS = 'components'

function getChildrenKey(data, isMobile) {
    if (isMobile && data.mobileComponents) {
        return MOBILE_COMPONENTS
    }
    return data.children ? MASTER_PAGE_CHILDREN : COMPONENTS
}

function getChildrenComponents(structure, isMobile) {
    const children = isMobile ? structure.mobileComponents : structure.children
    return children || structure.components || []
}

const omitPaths = [MASTER_PAGE_CHILDREN, MOBILE_COMPONENTS]

///////////////////////////////////////////////////////////////////////////////////////////
// old code
const getAllCompsInStructure = (compStructure, isMobile) => {
    const queue = [compStructure]
    for (let i = 0; i < queue.length; i++) {
        const component = queue[i]
        const childrenData = _.map(
            getChildrenComponents(component, isMobile),
            childStructure => ({...childStructure, parent: component.id}))
        queue.push(...childrenData)
    }
    return queue.reduce((acc, val) => Object.assign(acc, {[val.id]: val}), {})
}
const convertNestedPageStructureToFlatOld = (structure, pageId, isMobile) => {
    const convertChildrenToIds = comp => {
        const newComp = {..._.omit(comp, [MASTER_PAGE_CHILDREN, MOBILE_COMPONENTS]), metaData: {...comp.metaData, pageId}}
        const compChildren = comp[getChildrenKey(comp, isMobile)]
        return compChildren ? {...newComp, components: compChildren.map(item => item.id)} : newComp
    }
    const struct = getAllCompsInStructure(structure, isMobile)
    return Object.keys(struct).reduce((acc, id) => Object.assign(acc, {[id]: convertChildrenToIds(struct[id])}), {})
}
///////////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////////////
// new code
const convertChildrenToIds = (comp, parentId, isMobile, pageId) => {
    const compChildren = comp[getChildrenKey(comp, isMobile)]
    const newComp = _.omit(comp, omitPaths)
    newComp.metaData = {...comp.metaData, pageId}
    if (parentId) {
        newComp.parent = parentId
    }
    if (compChildren) {
        newComp.components = compChildren.map(item => item.id)
    }
    return newComp
}

const flattenRecursive = (structure, parentId, pageId, isMobile, result) => {
    const id = structure.id
    result[id] = convertChildrenToIds(structure, parentId, isMobile, pageId)
    getChildrenComponents(structure, isMobile).forEach(childStructure => {
        flattenRecursive(childStructure, id, pageId, isMobile, result)
    })
}

const convertNestedPageStructureToFlatNew = (structure, pageId, isMobile) => {
    const result = {}
    flattenRecursive(structure, undefined, pageId, isMobile, result)
    return result
}
///////////////////////////////////////////////////////////////////////////////////////////

module.exports = function convertNestedPageStructureToFlat(structure, pageId, isMobile, experimentInstance) {
    if (experimentInstance && experimentInstance.isOpen('bv_flatten_structure')) {
        return convertNestedPageStructureToFlatNew(structure, pageId, isMobile)
    }
    return convertNestedPageStructureToFlatOld(structure, pageId, isMobile)
}
