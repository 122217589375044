/* eslint-disable fp/no-unused-expression, no-undef */
'use strict'

const _ = require('lodash')
const ORIGINAL_HREF_ATTR = 'data-originalHref'

function patchAnchorLinks(siteData) {
    $('a[data-anchor]').each((index, a) => {
        const url = siteData ? _.get(siteData, ['currentUrl', 'full'], '') : window.location.href
        const urlWithoutParams = _.split(url, '?')[0]
        if (!_.includes(a.href, '#') && a.href.startsWith(urlWithoutParams)) {
            const originalHref = a.getAttribute('href')
            a.setAttribute(ORIGINAL_HREF_ATTR, originalHref)

            const anchorCompId = a.getAttribute('data-anchor-comp-id')
            a.setAttribute('href', `#${anchorCompId}`)
        }
    })
}

function restoreAnchorLinks() {
    $(`a[${ORIGINAL_HREF_ATTR}]`).each((index, a) => {
        const originalHref = a.getAttribute(ORIGINAL_HREF_ATTR)
        a.setAttribute('href', originalHref)
        a.removeAttribute(ORIGINAL_HREF_ATTR)
    })
}

module.exports = {
    patchAnchorLinks,
    restoreAnchorLinks
}

/* eslint-enable fp/no-unused-expression, no-undef */
