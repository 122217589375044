function isIFrame() {
	try {
		if (window.self === window.top) {
			return ''
		}
	} catch (e) {
		// empty
	}
	return 'iframe'
}

module.exports = {isIFrame}
