const _ = require('lodash')

module.exports = ({fetchFunction}) => {
    const APP_JSON_TYPE = 'application/json'
    const TYPES_WITHOUT_CONTENT = ['GET', 'DELETE']

    const wrapErrorHandler = handler => response => {
        // The first argument to the error handler function should be the XHR object, which includes fields such as "responsetText"
        if (typeof response.text === 'function') {
            // "response" here is a fetch "Response" object
            response.clone().text().then(responseText => handler(Object.assign(response, {response: responseText, responseText})))
        } else {
            // "response" here is most likely an Error object, thus we fail to uphold the zepto contract :(
            handler(response)
        }
    }

    // Consumers of this code were expecting the zepto XHR contract
    const ajaxMethod = (options = {}) => {
        options.headers = options.headers || {}
        const contentType = options.headers['Content-Type'] || options.contentType
        if (contentType) {
            options.headers['Content-Type'] = contentType
        } else if (!options.data && !_.includes(TYPES_WITHOUT_CONTENT, options.type)) {
            options.headers['Content-Type'] = APP_JSON_TYPE
        }
        const acceptHeaders = options.headers.Accept
        if (_.includes(options.headers['Content-Type'], APP_JSON_TYPE) && !acceptHeaders) {
            options.headers.Accept = APP_JSON_TYPE
        }

        if (_.get(options, 'xhrFields.withCredentials')) {
            options.credentials = 'include'
        }

        return fetchFunction(options.url, {method: options.type, body: options.data, ...options}, options.dataType)
            .then(options.success)
            .catch(wrapErrorHandler(options.error))
    }

    return {
        registerAjaxMethod: warmupUtils => {
            warmupUtils.ajaxLibrary.register(ajaxMethod)
        }
    }
}
