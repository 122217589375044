const _ = require('lodash')
const overrideClientSpecMapScriptsFromQuery = require('santa-main-r/lib/lib/common/overrideClientSpecMapScriptsFromQuery').default

// copied from https://github.com/wix-private/santa-core/blob/master/santa-main-r/src/lib/common/getQueryUtils.js
const getParameterFromQuery = (query, name) => {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
    const regex = new RegExp(`[\\?&]${name}=([^&#]*)`)
    const results = regex.exec(query)
    return results && results[1] ? decodeURIComponent(results[1]).replace(/\+/g, ' ') : ''
}

function hasWixCode(wixCodeModel) {
    return !!_.get(wixCodeModel, ['appData', 'codeAppId'])
}

function getSiteExtensionAppDataIfExist(clientSpecMap) {
    const wixCodeAppData = _.find(clientSpecMap, {type: 'siteextension'})
    if (wixCodeAppData && !_.get(wixCodeAppData, 'permissions.revoked')) {
        return wixCodeAppData
    }
    return null
}

function addDataBindingIfNeeded(clientSpecMap, wixCodeModel, serviceTopology, previewMode) {
    const wixCodeInSite = hasWixCode(wixCodeModel)
    if (wixCodeInSite && !_.find(clientSpecMap, {appDefinitionId: 'dataBinding'})) {
        const wixCodeAppData = getSiteExtensionAppDataIfExist(clientSpecMap)
        const dataBindingFile = previewMode ? 'app.verbose.js' : 'app.js'
        const dataBindingScriptsLocation = _.get(serviceTopology, ['scriptsLocationMap', 'dbsm-viewer-app'])
        _.assign(clientSpecMap, {
            fakeApplicationId: {
                appDefinitionId: 'dataBinding',
                appDefinitionName: 'Data Binding',
                applicationId: 'fakeApplicationId',
                appFields: {
                    platform: {
                        viewerScriptUrl: `${dataBindingScriptsLocation}/${dataBindingFile}`
                    }
                },
                type: 'Application',
                instance: wixCodeAppData.instance,
                instanceId: wixCodeAppData.instanceId
            }
        })
    }
}

function addDataBindingAndWixCodeUrlIfNeeded(clientSpecMap, serviceTopology, wixCodeModel, previewMode) {
    addDataBindingIfNeeded(clientSpecMap, wixCodeModel, serviceTopology, previewMode)
}


module.exports = {
    applyGradualRolloutOverrides: clientSpecMap => {
        _(clientSpecMap)
            .filter(entry => _.isPlainObject(_.get(entry, 'appFields.gradualRolloutOverrides')))
            .forEach(entry => {
                _.forEach(entry.appFields.gradualRolloutOverrides, (value, key) => {
                    if (_.isString(value)) {
                        _.set(entry, key, value) 
                     } else if (_.isObject(value)) {
                        _.merge(entry, {[key]: value})
                     }
                })
            })
    },
    overrideClientSpecMapScriptsFromQuery: (rawUrl, serviceTopology, clientSpecMap) => {
        const componentScriptsOverrideParam = getParameterFromQuery(rawUrl, 'widgetsUrlOverride')
        const viewerScriptOverrideParam = getParameterFromQuery(rawUrl, 'viewerPlatformOverrides')
        const controllerScriptsOverrideParam = getParameterFromQuery(rawUrl, 'controllersUrlOverride')
        const overridePlatformBaseUrlsParam = getParameterFromQuery(rawUrl, 'overridePlatformBaseUrls')

        overrideClientSpecMapScriptsFromQuery({
            clientSpecMap,
            viewerScriptOverrideParam,
            controllerScriptsOverrideParam,
            componentScriptsOverrideParam,
            overridePlatformBaseUrlsParam,
            scriptsLocation: serviceTopology.staticServerUrl
        })
    },
    removeApps: (clientSpecMap, disableAppsString) => {
        if (!disableAppsString) {
            return clientSpecMap
        }
        const apps = disableAppsString.toLowerCase().split(',')
        return _.omitBy(clientSpecMap, ({appDefinitionId, type}) => {
            if (type === 'siteextension') {
                return apps.includes(appDefinitionId) || apps.includes('wixcode'.toLowerCase())
            }
            return apps.includes(appDefinitionId)
        })
    },
    addDataBindingAndWixCodeUrlIfNeeded
}
