const {MasterBsiManager} = require('@wix/bsi-manager/dist/src/manager-master')

const generateGuid = () =>
	'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, e => {
		const i = 16 * Math.random() | 0
		return (e === 'x' ? i : 3 & i | 8).toString(16)
    })

const createMasterBsiManager = (
    consentPolicy,
    getCommonConfig,
    setCommonConfigValue
) => {
    const listeners = []

    if (typeof window !== 'undefined') { // CSR
        const bsiWrapper = {
            registerToBsiChanges: listener => listeners.push(listener),
            notifyListenersOnBsiChange: newValue => listeners.forEach(listener => listener(newValue))
        }

        const bsiManager = new MasterBsiManager().init({
            genGuid: generateGuid,
            getCommonConfig: () => ({
                get: key => getCommonConfig()[key],
                set: (key, value) => {
                    setCommonConfigValue('bsi', value)// update hostInstance
                    bsiWrapper.notifyListenersOnBsiChange(value) // notify bolt instance
                }
            }),
            getConsentPolicy: () => consentPolicy
        })
        bsiWrapper.instance = bsiManager
        
        return bsiWrapper
    }
    return null
}


module.exports = {
    createMasterBsiManager
}
